import { useState, useContext, useEffect } from "react";
import truncateWallet from "../../utils/truncateWallet";
import { TopWinnerItem } from "./topWinnerItem.jsx";
import { LeaderboardContext } from "../../provider/leaderboard";
import { nanoid } from "nanoid";

import "./index.scss";

const TopWinner = (props) => {
  const { searchInput, loadingAT, setAlreadyWon, alreadyWon, winnersEns } =
    useContext(LeaderboardContext);
  const [sortedTop, setSortedTop] = useState([]);
  const [topResult, setTopResult] = useState([]);

  useEffect(() => {
    const sortedUsers = props.result.data?.sort((a, b) => a.lose - b.lose);
    setSortedTop(sortedUsers);
  }, [props.result]);

  useEffect(() => {
    setTopResult(sortedTop);
    // console.log("top result:", props.result.data);
  }, [sortedTop]);

  useEffect(() => {
    if (searchInput) {
      const filtered = topResult.filter((res) => {
        if (res.ens) {
          return res.ens.toLowerCase().includes(searchInput);
        }
        return res.contractaddress.toLowerCase().includes(searchInput);
      });
      setTopResult(filtered);
    } else {
      setTopResult(props.result.data);
    }
  }, [searchInput, props.result]);

  return (
    <>
      {!loadingAT ? (
        topResult?.length > 0 ? (
          topResult.map((res, index) => {
            return (
              <div key={nanoid()}>
                <TopWinnerItem
                  index={index}
                  leaderboard={res}
                  setAlreadyWon={setAlreadyWon}
                  alreadyWon={alreadyWon}
                  loading={loadingAT}
                />
              </div>
            );
          })
        ) : (
          <div className="no-result">
            <p>No result found...</p>
          </div>
        )
      ) : (
        <div className="no-result">
          <p>Loading...</p>
        </div>
      )}
    </>
  );
};

export default TopWinner;
