import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";

import { InjectedConnector } from "@web3-react/injected-connector";
import { useWeb3React } from "@web3-react/core";
import { LeaderboardContext } from "../../provider/leaderboard";
import { UserContext } from "../../provider/user";
import { SERVER_LOGS } from "../../constants/env";
import { useENS } from "../../utils/getENS";

import "./NavBar.scss";
// Assets
import LogoGold from "../../assets/media/images/bearbull.png";
import { ReactComponent as HamburgerIcon } from "../../assets/media/icons/hamburgerMenu.svg";
import { ReactComponent as CloseIcon } from "../../assets/media/icons/CancelIcon.svg";
import truncateWallet from "../../utils/truncateWallet";
import WinPopup from "../WinPopup/WinPopup";

const NavBar = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const [isMobileSideMenuOpen, setIsMobileSideMenuOpen] = useState(false);
  const [calculateFilled, setCalculateFilled] = useState(false);

  const { getName, names } = useENS();

  // Success notification
  const notifySuccess = (message) => {
    toast.success(message, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      className: "toast-success",
    });
  };

  const user = useContext(UserContext);
  const navigate = useNavigate();
  const [profit, setProfit] = useState("...");
  const [lose, setLose] = useState("...");
  const [gain, setGain] = useState("");
  const {
    alreadyWon,
    setAlreadyWon,
    winners,
    topResult,
    getWeekResultAT,
    showModal,
    setShowModal,
    winnerLeaderboard,
  } = useContext(LeaderboardContext);
  const { active, account, library, connector, activate, deactivate } =
    useWeb3React();
  const [loading, setLoading] = useState(false);

  const injected = new InjectedConnector({
    supportedChainIds: [1, 3, 4, 5, 42],
  });

  async function connect() {
    try {
      await activate(injected);
      localStorage.setItem("isWalletConnected", true);
      notifySuccess("Wallet connected successfully!");
    } catch (ex) {
      console.log(ex);
    }
  }

  async function disconnect() {
    try {
      deactivate();
      localStorage.setItem("isWalletConnected", false);
      notifySuccess("Wallet disconnected successfully!");
    } catch (ex) {
      console.log(ex);
    }
  }

  useEffect(() => {
    const connectWalletOnPageLoad = async () => {
      if (localStorage?.getItem("isWalletConnected") === "true") {
        try {
          await activate(injected);
          localStorage.setItem("isWalletConnected", true);
        } catch (ex) {
          console.log(ex);
        }
      }
    };
    connectWalletOnPageLoad();
  }, []);

  const calculateWallet = (walletAddress) => {
    setLoading(true);
    const jsonData = {
      contractaddress: walletAddress,
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token 1b4d9825bd14c43da095124c59fa8e22e3be4980",
      },
    };

    axios
      .post("https://api.nftpnl.xyz/calculate/", jsonData, axiosConfig)
      .then((response) => {
        if (response.data.data.data.querystatus == "Filled") {
          setCalculateFilled(true);
          const gain = response.data.data.data.gain;
          const lose = response.data.data.data.lose;
          const profit = response.data.data.data.profit;
          if (gain == null) {
            setGain(0);
          } else {
            setGain(gain);
          }
          if (lose == null) {
            setLose(0);
          } else {
            setLose(response.data.data.data.lose);
          }
          if (profit == null) {
            setProfit(0);
          } else {
            setProfit(profit);
          }
        }
        if (response.data.data.data.querystatus == "failed") {
          setGain("...");
          setLose("...");
          setProfit("...");
        }
        setLoading(false);
        console.log(loading);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const endpoint = `${SERVER_LOGS}api/weblog`;

  const saveLogs = async () => {
    const userInfo = { walletAddress: account, projectName: "PnlTracker" };
    axios
      .post(endpoint, userInfo)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getWeekResultAT();
    calculateWallet(account);

    if (account) {
      saveLogs();
      const addr = account.toString();
      getName(addr);
      console.log("ens =>", names);
    }
  }, [account]);

  useEffect(() => {
    calculateWallet(account);
  }, [calculateFilled]);
  return (
    <>
      {showModal && alreadyWon && <WinPopup setShowModal={setShowModal} />}

      {/* Mobile NavBar */}
      <div
        className={
          isMobileSideMenuOpen ? "mobile-side-menu active" : "mobile-side-menu"
        }
      >
        <div className="close-btn-wrapper">
          <div
            onClick={() => setIsMobileSideMenuOpen(false)}
            className="close-btn"
          >
            <CloseIcon />
          </div>
        </div>
        <a href="https://bearandbull.game/" rel="noreferrer" target={"_blank"}>
          HOME
        </a>
        {/* <a
          href="https://barbearians-front-end.pages.dev/missions"
          rel="noreferrer"
          target={"_blank"}
        >
          MISSIONS
        </a> */}
        <a
          href="https://bearandbull.game/whitelist-quests"
          rel="noreferrer"
          target={"_blank"}
        >
          WHITELIST QUESTS
        </a>
        {/* <a
          href="https://barbearians-front-end.pages.dev/theallowlist"
          rel="noreferrer"
          target={"_blank"}
        >
          THE ALLOWLIST
        </a> */}
        {/* <a
          href="https://barbearians-front-end.pages.dev/WHITEpaper"
          rel="noreferrer"
          target={"_blank"}
        >
          WHITEPAPER
        </a> */}
      </div>
      {/* Mobile NavBar */}

      <div className="navbar">
        <div className="navbar-top">
          <div className="nav-left-side">
            <a
              href="https://bearandbull.game/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={LogoGold} alt="" />
            </a>

            <a
              href="https://bearandbull.game/"
              rel="noreferrer"
              target={"_blank"}
            >
              HOME
            </a>
            {/* <a
              href="https://barbearians-front-end.pages.dev/missions"
              rel="noreferrer"
              target={"_blank"}
            >
              MISSIONS
            </a> */}
            <a
              href="https://bearandbull.game/whitelist-quests"
              rel="noreferrer"
              target={"_blank"}
            >
              WHITELIST QUESTS
            </a>
            {/* <a
              href="https://barbearians-front-end.pages.dev/theallowlist"
              rel="noreferrer"
              target={"_blank"}
            >
              THE ALLOWLIST
            </a> */}
            {/* <a
                  href="https://barbearians-front-end.pages.dev/WHITEpaper"
                  rel="noreferrer"
                  target={"_blank"}
                >
                  WHITEPAPER
                </a> */}
          </div>

          <div className="nav-right-side">
            {active ? (
              <div onClick={disconnect} className="login-button">
                {!names ? (
                  <div className="user">{truncateWallet(account)}</div>
                ) : (
                  <div className="user">{names}</div>
                )}
                <div className="logout">LOGOUT</div>
              </div>
            ) : (
              <div onClick={connect} className="login-button">
                Connect
              </div>
            )}
            <div
              onClick={() => setIsMobileSideMenuOpen(true)}
              className="mobile-side"
            >
              <HamburgerIcon className="mobile-side-icon" />
            </div>
          </div>
        </div>
        <div className="navbar-bottom">
          <div className="allowlist-game">ALLOWLIST GAME</div>
          <div className="eth-simulator">P&L TRACKER</div>
          <div className="leaderboard-title-header">
            {winnerLeaderboard ? "WINNER LEADERBOARD" : "LOSER LEADERBOARD"}
          </div>
          {account && (
            <div className="user-table-info-wrapper">
              <div className="user-table-info-highlight" />
              <div className="user-table-info">
                <div className="user-table-left">
                  <div className="user-table-left-top">YOUR STATS</div>
                  <div className="user-table-left-bottom">
                    {!names ? (
                      <div className="user-name-wallet">
                        {truncateWallet(account)}
                      </div>
                    ) : (
                      <div className="user-name-wallet">{names}</div>
                    )}
                  </div>
                </div>
                <div className="user-table-right">
                  <div className="user-table-right-top">
                    <div className="user-table-lost">LOST</div>
                  </div>
                  <div className="user-table-right-bottom">
                    <div className="loss">
                      {loading && !calculateFilled ? (
                        <> ... </>
                      ) : (
                        <> {parseFloat(lose).toFixed(2) || 0} ETH </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="navbar-bottom-gradient" />
        </div>
      </div>
    </>
  );
};

export default NavBar;
