import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import "./index.scss";
import truncateWallet from "../../utils/truncateWallet";
// Assets
import GoldMedal from "../../assets/media/icons/goldMedal.png";
import SilverMedal from "../../assets/media/icons/silverMedal.png";
import BronzeMedal from "../../assets/media/icons/bronzeMedal.png";

export const TopWinnerItem = ({ leaderboard, index }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });

  // rateLostRaise function that calculates the rate of lost or raise
  const rateLostRaise = (prev, current) => {
    const rate = (-(prev - current) / prev) * 100;
    // console.log(rate);
    if (rate < 0) return `${rate.toFixed(2)}%`;
    if (rate > 0) return `+${rate.toFixed(2)}%`;
    if (rate == 0) return "0.00%";
  };

  // lostOrRaise function that checks if the rate is positive or negative
  const lostOrRaise = (prev, current) => {
    const rate = ((prev - current) / prev) * 100;
    if (rate < 0) return "green";
    if (rate > 0) return "red";
    if (rate == 0) return "white";
  };

  // rankCheck function that checks the rank of the user and returns the corresponding class
  const rankCheck = (index) => {
    switch (index) {
      case 0:
        return "top-rank one";
      case 1:
        return "top-rank two";
      case 2:
        return "top-rank three";
      default:
        return "top-rank";
    }
  };

  return (
    <>
      <div className="top-canditate-wrapper">
        <div className="top-candidate-left-side">
          <div className={rankCheck(index)}>
            {index === 0 && <img src={GoldMedal} alt="" />}
            {index === 1 && <img src={SilverMedal} alt="" />}
            {index === 2 && <img src={BronzeMedal} alt="" />}
            <div className="top-rank-number">{index + 1}</div>
          </div>
          <div className="top-wallet-adress">
            {!leaderboard.ens
              ? leaderboard.contractaddress.length < 10
                ? leaderboard.contractaddress
                : truncateWallet(leaderboard.contractaddress)
              : leaderboard.ens}
          </div>
        </div>

        <div className="weekly-candidate-right-side">
          <div className="weekly-candidate-lostgainedamount">
            <span>
              {leaderboard.lose == null
                ? 0
                : parseFloat(leaderboard.lose).toFixed(2)}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
