import { createContext, useEffect, useState } from "react";
import axios from "axios";
import { ethers } from "ethers";

const WalletContext = createContext();

const WalletProvider = ({ children }) => {
  const [signer, setSigner] = useState();
  const [profit, setProfit] = useState();
  const [lose, setLose] = useState();
  const [gain, setGain] = useState();
  const [userAddress, setUserAddress] = useState("");

  const truncateWallet = (input) => {
    if (input.length > 10) {
      return (
        input.substring(0, 5) +
        "..." +
        input.substring(input.length - 4, input.length)
      );
    }
    return input;
  };

  const connectWallet = async () => {
    if (!window.ethereum) {
      alert("No Ethereum wallet detected.");
      throw "No Ethereum wallet";
    }

    let provider = new ethers.providers.Web3Provider(window.ethereum);
    await provider.send("eth_requestAccounts", []);
    let s = provider.getSigner();
    setSigner(s);
    const wallet = await s.getAddress();
    setUserAddress(truncateWallet(wallet));
    console.log(signer);
    console.log("wallet", userAddress);
  };

  const getWallet = async () => {
    if (!window.ethereum) {
      alert("No Ethereum wallet detected.");
      throw "No Ethereum wallet";
    }
    let provider = new ethers.providers.Web3Provider(window.ethereum);
    let s = provider.getSigner();
    setSigner(s);
    const wallet = await s.getAddress();
    setUserAddress(truncateWallet(wallet));
    calculateWallet(wallet);
  };

  const calculateWallet = (walletAddress) => {
    const jsonData = {
      contractaddress: walletAddress,
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token 1b4d9825bd14c43da095124c59fa8e22e3be4980",
      },
    };

    axios
      .post("https://api.nftpnl.xyz/calculate/", jsonData, axiosConfig)
      .then((response) => {
        console.log("calculate", response.data);
        const gain = response.data.data.data.gain;
        const lose = response.data.data.data.lose;
        const profit = response.data.data.data.profit;
        if (gain == null) {
          setGain(0);
        } else {
          setGain(gain);
        }
        if (lose == null) {
          setLose(0);
        } else {
          setLose(lose);
        }
        if (profit == null) {
          setProfit(0);
        } else {
          setProfit(profit);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getWallet();
  }, [userAddress]);

  const data = {
    connectWallet,
    getWallet,
    userAddress,
    gain,
    lose,
    profit,
  };

  return (
    <WalletContext.Provider value={data}>{children}</WalletContext.Provider>
  );
};

export default WalletProvider;

export { WalletContext };
