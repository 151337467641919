import { useEffect } from "react";
import useSound from "use-sound";
import gsap from "gsap";
import { useWindowSize } from "react-use";
import Confetti from "react-confetti";

// Styles
import "./WinPopup.scss";

// Assets
import wonSound from "../../assets/media/sounds/wonPopup.mp3";

const WinPopup = ({ setShowModal }) => {
  const [playWonSound] = useSound(wonSound, { volume: 0.3 });
  const { width, height } = useWindowSize();

  useEffect(() => {
    const tl = gsap.timeline({ defaults: { ease: "sine.inOut" } });
    playWonSound();

    tl.to(".winpopup-back", { opacity: 1, delay: 0.5 })
      .to(".winpopup", { width: "36rem", opacity: 1 })
      .to(".congrats-message, .okay-button", {
        opacity: 1,
        y: 0,
        stagger: 0.5,
      });
  }, []);

  const closeModalHandler = () => {
    gsap.to([".winpopup-back", ".winpopup", "canvas"], {
      opacity: 0,
      stagger: 0.25,
      duration: 1,
      onComplete: () => setShowModal(false),
    });
  };

  // Closing the modal with the escape key
  const handleEscape = (event) => {
    if (event.code === "Escape") {
      closeModalHandler();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  });

  return (
    <>
      <Confetti gravity={0.4} recycle={false} width={width} height={height} />
      <div onClick={closeModalHandler} className="winpopup-back" />
      <div className="winpopup">
        <p className="congrats-message">
          Congratulations on realizing the most losses trading NFTs on
          secondary. You have won an allowlist spot.
        </p>
        <div onClick={closeModalHandler} className="okay-button">
          Close
        </div>
      </div>
    </>
  );
};

export default WinPopup;
