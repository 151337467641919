import { useState, useContext, useEffect, useCallback, useMemo } from "react";
import { LeaderboardContext } from "../../provider/leaderboard";
import { OrderContext } from "../../provider/order";
import WeekResult from "../weekResult";
import TopWinner from "../topWinner";
import "./index.scss";

const Termresult = ({ thisWeek, lastWeek, allTime }) => {
  const leaderboard = useContext(LeaderboardContext);
  const order = useContext(OrderContext);

  useEffect(() => {
    leaderboard.getWeekResult();
  }, []);

  const lastWeekResult = useCallback(() => {
    let result = [];

    if (Array.isArray(leaderboard.lastWeekResult)) {
      result = leaderboard.lastWeekResult.map((el) => {
        return {
          userName: el.attributes.contractaddress,
          gain: el.attributes.gain,
          lose: el.attributes.lose,
          profit: el.attributes.profit,
        };
      });

      result.sort(function (a, b) {
        return a.gain - a.lose - (b.gain - b.lose);
      });

      result.reverse();
    }

    return result;
  }, [leaderboard.lastWeekResult]);

  const currentWeekResult = useCallback(() => {
    let result = [];

    if (Array.isArray(leaderboard.currentWeekResult)) {
      result = leaderboard.currentWeekResult.map((el) => {
        return {
          userName: el.attributes.contractaddress,
          gain: el.attributes.gain,
          lose: el.attributes.lose,
          profit: el.attributes.profit,
        };
      });

      result.sort(function (a, b) {
        return parseFloat(a.lose) - parseFloat(b.lose);
      });

      result.reverse();
    }

    return result;
  }, [leaderboard.currentWeekResult, order.ethPrice]);

  return (
    <>
      {thisWeek && (
        <WeekResult
          title="Current Week"
          result={leaderboard.currentWeekResult}
          loading={leaderboard.loadingTW}
          winner={leaderboard.currentWeekEns}
        />
      )}
      {lastWeek && (
        <WeekResult
          title="Previous Week"
          result={leaderboard.lastWeekResult}
          loading={leaderboard.loadingLW}
          winner={leaderboard.lastWeekEns}
        />
      )}
      {allTime && (
        <TopWinner
          result={leaderboard.topResult}
          loading={leaderboard.loadingAT}
        />
      )}
    </>
  );
};

export default Termresult;
