import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Error.scss";

import Footer from "../../components/Footer/Footer";
import goldenBearError from "../../assets/media/images/goldenBearArmor.gif";
import errorSpeechBubble from "../../assets/media/images/404errorbubble.png";

const Error = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Bear & Bull Game • 404";
  }, []);
  return (
    <>
      <div className="error-page">
        <div className="error-bear">
          <img className="error-bear-video" src={goldenBearError} alt="" />
          <img className="speech-bubble" src={errorSpeechBubble} alt="" />
        </div>
        <div onClick={() => navigate("/")} className="gotohome-button">
          GO TO HOME
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Error;
