import { useState, useContext, useEffect, useRef } from "react";
import { LeaderboardContext } from "../../provider/leaderboard";
import { UserContext } from "../../provider/user";
import { useMediaQuery } from "react-responsive";
import { useWeb3React } from "@web3-react/core";

// Assets
import { ReactComponent as SearchIcon } from "../../assets/media/icons/SearchIcon.svg";

// Components
import Header from "../../components/header";
import Footer from "../../components/Footer/Footer";
import Termresult from "../../components/termresult";
import PLTrackerNoWallet from "./PLTrackerNoWallet";
import WinPopup from "../../components/WinPopup/WinPopup";
import HourCountdownTimer from "../../components/CountdownTimer/HourCountDownTimer";
import Notification from "../../components/Notification/Notification";

// Styles
import "./index.scss";

const LeaderBoard = (props) => {
  const isTabletDevice = useMediaQuery({ query: "(max-width: 889px)" });
  const isSmallDevice = useMediaQuery({
    query: "(max-width: 480px)",
  });
  const searchBarRef = useRef(null);
  const searchBarRefMobile = useRef(null);

  const [userLost, setUserLost] = useState(45);
  const [isNotificationOpen, setIsNotificationOpen] = useState(true);

  const [showWinnerPopup, setShowWinnerPopup] = useState(false);
  // This is tweak, please change to false when done.
  // Waiting for functionality for it.

  const { account } = useWeb3React();
  const [thisWeek, setThisWeek] = useState(true);
  const [lastWeek, setLastWeek] = useState(false);
  const [allTime, setAllTime] = useState(false);

  const { handleSearch, handleInputPaste, setSearchInput } =
    useContext(LeaderboardContext);
  const { userInfo } = useContext(UserContext);

  useEffect(() => {
    document.title = "Bear & Bull Game • P&L Tracker";
  }, []);

  useEffect(() => {
    const handleReset = (event) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target)
      ) {
        searchBarRef.current.value = "";
        searchBarRefMobile.current.value = "";
        setSearchInput("");
      }
    };

    document.addEventListener("mousedown", handleReset);

    return () => {
      document.removeEventListener("mousedown", handleReset);
    };
  }, []);

  const handleBackspace = (event) => {
    // Backspace delete
    if (event.keyCode === 8) {
      searchBarRef.current.value = "";
      searchBarRefMobile.current.value = "";
      setSearchInput("");
    }
  };

  useEffect(() => {
    // Add event listener to document to listen for keydown event
    window.addEventListener("keydown", handleBackspace);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener("keydown", handleBackspace);
    };
  }, []);

  // Chart navigation buttons
  const chartButtonHandler = (pageName) => {
    switch (pageName) {
      case "thisWeek":
        setThisWeek(true);
        setLastWeek(false);
        setAllTime(false);
        break;

      case "lastWeek":
        setThisWeek(false);
        setLastWeek(true);
        setAllTime(false);
        break;

      case "allTime":
        setThisWeek(false);
        setLastWeek(false);
        setAllTime(true);
        break;

      default:
        break;
    }
  };

  return account ? (
    <>
      {isNotificationOpen && (
        <Notification
          setIsNotificationOpen={setIsNotificationOpen}
          userLost={userLost}
        />
      )}
      {isTabletDevice && <Header />}
      {/* Mobile search */}
      <div className="search-mobile">
        <SearchIcon className="search-icon" />
        <input
          ref={searchBarRefMobile}
          type="text"
          className="leaderboard-search"
          placeholder="SEARCH ADDRESS"
          onChange={(e) => handleSearch(e)}
          onPaste={(e) => handleInputPaste(e)}
        />
      </div>
      {/* Mobile search */}
      {showWinnerPopup && <WinPopup />}
      <div className="leaderboard">
        <div className="leaderboard-page-top">
          <div className="left-side">
            <div className="leaderboard-chart-wrapper">
              <div className="leaderboard-chart-top-buttons">
                <div className="leaderboard-chart-top-left">
                  <div
                    className={
                      thisWeek ? "this-week-button active" : "this-week-button"
                    }
                    onClick={() => chartButtonHandler("thisWeek")}
                  >
                    THIS WEEK
                  </div>
                  <div
                    className={
                      lastWeek ? "last-week-button active" : "last-week-button"
                    }
                    onClick={() => chartButtonHandler("lastWeek")}
                  >
                    LAST WEEK
                  </div>
                  <div
                    className={
                      allTime ? "all-time-button active" : "all-time-button"
                    }
                    onClick={() => chartButtonHandler("allTime")}
                  >
                    ALL TIME
                  </div>
                </div>

                <div className="leaderboard-chart-top-right">
                  <SearchIcon className="search-icon" />
                  <input
                    ref={searchBarRef}
                    type="text"
                    className="leaderboard-search"
                    placeholder="SEARCH ADDRESS"
                    onChange={(e) => handleSearch(e)}
                    onPaste={(e) => handleInputPaste(e)}
                  />
                </div>
              </div>
              <div className="leaderboard-top-table-headers">
                <div className="leaderboard-top-table-header-left">
                  <div className="leaderboard-top-table-header-rank">RANK</div>
                  <div className="leaderboard-top-table-header-wallet-address">
                    {!isSmallDevice && "WALLET"} ADDRESS
                  </div>
                </div>

                <div className="leaderboard-top-table-header-right">
                  <div className="leaderboard-top-table-header-lost-gained-amount">
                    LOST {!isSmallDevice && "AMOUNT"}
                  </div>
                </div>
              </div>
              <div className="leaderboard-list">
                <Termresult
                  thisWeek={thisWeek}
                  lastWeek={lastWeek}
                  allTime={allTime}
                />
              </div>
            </div>
          </div>

          <div className="right-side">
            {!isTabletDevice && <Header />}
            <div className="region-filters-box">
              <div className="rules-eth-sim">THIS WEEK'S TIMER</div>
              <div className="logintojoin-leaderboard">
                <HourCountdownTimer />
              </div>
            </div>
            <div className="leaderboard-warning">
              To be part of the leaderboard you must be in the TOP 100.
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  ) : (
    <PLTrackerNoWallet />
  );
};

export default LeaderBoard;
