import { useState, useEffect } from 'react';

function HourCountdownTimer() {
  const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining(getTimeRemaining());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  function getTimeRemaining() {
    const now = new Date();
    const minutesUntilNextHour = 59 - now.getUTCMinutes();
    const secondsUntilNextHour = 60 - now.getUTCSeconds();
    const timeRemainingMs = minutesUntilNextHour * 60 * 1000 + secondsUntilNextHour * 1000;
    const secondsRemaining = Math.floor(timeRemainingMs / 1000);
    return secondsRemaining;
  }

  function formatTimeRemaining() {
    const minutes = Math.floor(timeRemaining / 60);
    const seconds = timeRemaining % 60;
    return `${minutes.toString().padStart(2, '0')} MINUTES : ${seconds.toString().padStart(2, '0')} SECONDS LEFT`;
  }

  return (
    <div>
      <h2>Countdown to Next Hour</h2>
      <p>{formatTimeRemaining()}</p>
    </div>
  );
}

export default HourCountdownTimer;
