import React, { useContext, useState, useEffect } from "react";
import truncateWallet from "../../utils/truncateWallet";
import { LeaderboardContext } from "../../provider/leaderboard";
import { toast } from "react-toastify";
import "./index.scss";
import { ethers } from "ethers";
// Assets
import GoldMedal from "../../assets/media/icons/goldMedal.png";
import SilverMedal from "../../assets/media/icons/silverMedal.png";
import BronzeMedal from "../../assets/media/icons/bronzeMedal.png";
import { ReactComponent as AllowedIcon } from "../../assets/media/icons/Allowed.svg";
import { namehash } from "ethers/lib/utils";

const WeekResult = (props) => {
  const { searchInput } = useContext(LeaderboardContext);
  const { filteredLastWeekResult, filteredCurrentWeekResult } =
    useContext(LeaderboardContext);
  const [winners, setWinners] = useState([]);
  const [users, setUsers] = useState([]);
  const winnerClassName = "weekly-canditate-wrapper winner";
  const nonWinnerClassName = "weekly-canditate-wrapper non-winner";

  // const [getName,names] = useENS()
  // rateLostRaise function that calculates the rate of lost or raise
  const rateLostRaise = (prev, current) => {
    const rate = (-(prev - current) / prev) * 100;
    if (rate < 0) return `${rate.toFixed(2)}%`;
    if (rate > 0) return `${rate.toFixed(2)}%`;
    if (rate === 0) return "0.00%";
  };

  // lostOrRaise function that checks if the rate is positive or negative
  const lostOrRaise = (prev, current) => {
    const rate = ((prev - current) / prev) * 100;
    if (rate < 0) return "green";
    if (rate > 0) return "red";
    if (rate === 0) return "white";
  };

  // rankCheck function that checks the rank of the user and returns the corresponding class
  const rankCheck = (index) => {
    switch (index) {
      case 0:
        return "weekly-rank one";
      case 1:
        return "weekly-rank two";
      case 2:
        return "weekly-rank three";
      default:
        return "weekly-rank";
    }
  };

  useEffect(() => {
    setUsers(props.result);
    console.log("props.result", props.result);
  }, [props.result]);

  useEffect(() => {
    const filteredUsers = searchInput
      ? users?.filter((user) =>
          user.contractaddress.toLowerCase().includes(searchInput)
        )
      : props.result.data;
    setUsers(filteredUsers);
  }, [props.result, searchInput]);

  useEffect(() => {
    if (users?.length > 0) {
      // Sort the users based on their score in descending order
      const sortedUsers = users?.sort((a, b) => a.lose - b.lose);
      // Take the top 100 users
      const top100 = sortedUsers.slice(0, 3);
      setWinners(top100);
    }
  }, [users]);

  const notifySuccess = (message) => {
    toast.success(message, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      className: "toast-success",
    });
  };

  const copyHandler = (walletAdress) => {
    navigator.clipboard.writeText(walletAdress);
    notifySuccess("Wallet address copied to clipboard.");
  };

  return (
    <>
      {props.loading ? (
        <>
          <div className="no-result">
            <p>Loading...</p>
          </div>
        </>
      ) : users?.length > 0 ? (
        users?.map((el, index) => (
          <div
            onClick={() => copyHandler(el.contractaddress)}
            className={
              winners.includes(el) ? winnerClassName : nonWinnerClassName
            }
            key={index}
          >
            <div className="weekly-candidate-left-side">
              <div className={rankCheck(index)}>
                {winners.includes(el) && <AllowedIcon />}
                <div className="weekly-rank-number">{index + 1}</div>
              </div>
              <div className="weekly-canditate-username">
                {/*  */}
                {!el.ens
                  ? el.contractaddress.length < 10
                    ? el.contractaddress
                    : truncateWallet(el.contractaddress)
                  : el.ens}
              </div>
            </div>

            <div className="weekly-candidate-right-side">
              <div className="weekly-candidate-lostgainedamount">
                <span>
                  {el.lose == null ? 0 : parseFloat(el.lose).toFixed(2)}
                </span>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="no-result">
          <p>No results...</p>
        </div>
      )}
    </>
  );
};

export default WeekResult;
