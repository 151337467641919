import { useState, useContext } from "react";
import {
  DiscordLoginButton,
  TwitterLoginButton,
  createButton,
} from "react-social-login-buttons";
import { UserContext } from "../../provider/user";
import Modal from "../modal";
import "./index.scss";

const config = {
  text: "Connect wallet to join leaderboard",
  icon: "Ethereum",
  style: { background: "#000" },
  activeStyle: { background: "#261812" },
};

const MyEthereumLoginButton = createButton(config);

const LoginButton = () => {
  const user = useContext(UserContext);
  const [signUpModalShow, setSignUpModalShow] = useState(false);
  const [logInModalShow, setLogInModalShow] = useState(false);

  //logout logic user.logout from userContext
  return (
    <>
      <MyEthereumLoginButton
        onClick={async () => {
          localStorage.removeItem("id");
          localStorage.removeItem("jwt_token");
          let wallet = await user.connectWallet();
          if (wallet) {
            setSignUpModalShow(true);
          }
        }}
        className="login-button"
      />

      <Modal
        visible={signUpModalShow}
        close={() => setSignUpModalShow(false)}
        title="Sign Up"
      >
        <div className="flex-between border p4 m8">
          wallet: {user.userInfo.walletAddress}
        </div>
        <div className="flex-between">
          <DiscordLoginButton onClick={() => user.authWithDiscord()}>
            <span>Connect</span>
          </DiscordLoginButton>
        </div>
        <div className="flex-between">
          <TwitterLoginButton onClick={() => user.authWithTwitter()}>
            <span>Connect</span>
          </TwitterLoginButton>
        </div>
      </Modal>

      <Modal
        visible={logInModalShow}
        close={() => setLogInModalShow(false)}
        title="Log In"
      >
        <div className="flex-between">
          <MyEthereumLoginButton
            onClick={async () => {
              localStorage.removeItem("id");
              localStorage.removeItem("jwt_token");
              setLogInModalShow(false);
              let wallet = await user.connectWallet();
              if (wallet) {
                setSignUpModalShow(true);
              }
            }}
          >
            <span>Login with Wallet</span>
          </MyEthereumLoginButton>
        </div>
      </Modal>
    </>
  );
};

export default LoginButton;
