import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { SERVER_URL } from "../constants/env";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import truncateWallet from "../utils/truncateWallet";
const LeaderboardContextTemplate = {
  lastWeekResult: [{}],
  currentWeekResult: [{}],
  topResult: [{}],
  getWeekResult: () => {},
  getRoundInfo: () => {},
};

const LeaderboardContext = React.createContext(LeaderboardContextTemplate);

const LeaderboardProvider = ({ children }) => {
  const [lastWeekResult, setLastWeekResult] = useState([]);
  const [currentWeekResult, setCurrentWeekResult] = useState([]);
  const [topResult, setTopResult] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const { account } = useWeb3React();
  const [loadingAT, setLoadingAT] = useState(false);
  const [loadingLW, setLoadingLW] = useState(false);
  const [loadingTW, setLoadingTW] = useState(false);
  const [alreadyWon, setAlreadyWon] = useState(false);
  const [winners, setWinners] = useState([]);
  const [currentWeekEns, setCurrentWeekEns] = useState([]);
  const [lastWeekEns, setLastWeekEns] = useState([]);
  const [winnersEns, setWinnersEns] = useState([]);

  const [winnerLeaderboard, setWinnerLeaderboard] = useState(true);
  const [loserLeaderboard, setLoserLeaderboard] = useState(false);

  const infuraKey = "6d4d0e85906e4a6cb64700426b6ad209";
  const rpcUrl = `https://mainnet.infura.io/v3/${infuraKey}`;
  const provider = new ethers.providers.JsonRpcProvider(rpcUrl);

  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    getWeekResult();
  }, []);

  const getWeekResult = async () => {
    console.log("week");
    await getWeekResultAT();
    await getWeekResultLW();
    await getWeekResultTW();
  };

  const getWeekResultTW = async () => {
    setLoadingTW(true);
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token 1b4d9825bd14c43da095124c59fa8e22e3be4980",
      },
    };

    axios
      .get("https://api.nftpnl.xyz/thisweek/", axiosConfig)
      .then((response) => {
        console.log("this week", response.data);
        setCurrentWeekResult(response.data.data);
        // const ens = [];
        // response?.data.data.data.map((res) => {
        //   provider.lookupAddress(res.contractaddress).then((resolvedName) => {
        //     if (resolvedName == null) {
        //       const truncated = truncateWallet(res.contractaddress)
        //       ens.push(truncated);
        //     } else {
        //       ens.push(resolvedName);
        //     }
        //   });
        // });
        // setCurrentWeekEns(ens)
        setLoadingTW(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingTW(false);
      });
  };

  const getWeekResultLW = async () => {
    setLoadingLW(true);

    let axiosConfigLastW = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token 1b4d9825bd14c43da095124c59fa8e22e3be4980",
      },
    };

    axios
      .get("https://api.nftpnl.xyz/lastweek/", axiosConfigLastW)
      .then((response) => {
        console.log("last week", response.data);
        setLastWeekResult(response.data.data);
        // const ens = [];
        // response?.data.data.data.map((res) => {
        //   provider.lookupAddress(res.contractaddress).then((resolvedName) => {
        //     if (resolvedName == null) {
        //       const truncated = truncateWallet(res.contractaddress)
        //       ens.push(truncated);
        //     } else {
        //       ens.push(resolvedName);
        //     }
        //   });
        // });
        // setLastWeekEns(ens)
        setLoadingLW(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingLW(false);
      });
  };

  const getWeekResultAT = async () => {
    setLoadingAT(true);

    let axiosConfigAT = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token 1b4d9825bd14c43da095124c59fa8e22e3be4980",
      },
    };

    axios
      .get("https://api.nftpnl.xyz/winners/", axiosConfigAT)
      .then((response) => {
        console.log("top res", response.data.data);
        response?.data.data.data.map((winner) => {
          const wallet = account?.toLowerCase();
          const winnerAddress = winner.contractaddress.toLowerCase();
          if (wallet == winnerAddress) {
            setAlreadyWon(true);
            setShowModal(true);
          }
        });
        // const ens = [];
        // response?.data.data.data.map((res) => {
        //   provider.lookupAddress(res.contractaddress).then((resolvedName) => {
        //     if (resolvedName == null) {
        //       const truncated = truncateWallet(res.contractaddress)
        //       ens.push(truncated);
        //     } else {
        //       ens.push(resolvedName);
        //     }
        //   });
        // // });
        // setWinnersEns(ens);
        setTopResult(response.data.data);
        setLoadingAT(false);
      })
      .catch((error) => {
        console.error("alltime", error);
        setLoadingAT(false);
      });
  };

  const filteredTopResult = useMemo(() => {
    if (searchInput.length > 0 && Array.isArray(topResult)) {
      // if(searchInput[0]!= 0) {
      //   return winnersEns.filter((result) => {
      //     return result.match(searchInput);
      //   });
      // } else {
      return topResult.filter((result) => {
        return result?.contractaddress.match(searchInput);
      });
      // }
    } else {
      return topResult;
    }
  }, [searchInput, topResult]);

  const filteredLastWeekResult = useMemo(() => {
    if (searchInput.length > 0 && Array.isArray(lastWeekResult)) {
      // if(searchInput[0]!= 0) {
      //   return lastWeekEns.filter((result) => {
      //     return result.match(searchInput);
      //   });
      // } else {
      return lastWeekResult.filter((result) => {
        return result.attributes.contractaddress.match(searchInput);
      });
      // }
    } else {
      return lastWeekResult;
    }
  }, [searchInput, lastWeekResult]);

  const filteredCurrentWeekResult = useMemo(() => {
    if (searchInput.length > 0 && Array.isArray(currentWeekResult)) {
      // if(searchInput[0]!= 0) {
      //   return currentWeekEns.filter((result) => {
      //     return result.match(searchInput);
      //   });
      // } else {
      return currentWeekResult.filter((result) => {
        return result.attributes.contractaddress.match(searchInput);
      });
      // }
    } else {
      return currentWeekResult;
    }
  }, [searchInput, currentWeekResult]);

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchInput(escapeRegExp(e.target.value.toLowerCase()));
  };

  const handleInputPaste = (e) => {
    const value = e.clipboardData.getData("Text");
    setSearchInput(value);
  };

  return (
    <LeaderboardContext.Provider
      value={{
        topResult,
        lastWeekResult,
        currentWeekResult,
        getWeekResult,
        getWeekResultTW,
        getWeekResultAT,
        getWeekResultLW,
        searchInput,
        setSearchInput,
        handleSearch,
        filteredTopResult,
        filteredLastWeekResult,
        filteredCurrentWeekResult,
        loadingAT,
        loadingLW,
        loadingTW,
        setAlreadyWon,
        alreadyWon,
        winners,
        handleInputPaste,
        showModal,
        setShowModal,
        winnersEns,
        currentWeekEns,
        lastWeekEns,

        winnerLeaderboard,
        setWinnerLeaderboard,
        loserLeaderboard,
        setLoserLeaderboard,
      }}
    >
      {children}
    </LeaderboardContext.Provider>
  );
};

export { LeaderboardContext };
export default LeaderboardProvider;
