import { ethers } from "ethers";
import { useState, useRef } from "react";

const infuraKey = "6d4d0e85906e4a6cb64700426b6ad209";
const rpcUrl = `https://mainnet.infura.io/v3/${infuraKey}`;
const provider = new ethers.providers.JsonRpcProvider(rpcUrl);

export const useENS = () => {
  const [names, setNames] = useState();

  const getName = (addr) => {
    // if (names[addr]) return names[addr];
    provider.lookupAddress(addr).then((resolvedName) => {
      setNames(resolvedName);
      return resolvedName ?? addr;
    });
  };

  return {
    names,
    getName
  };
};