import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "./App.scss";

import "react-toastify/dist/ReactToastify.css";
// Pages
import UserProvider from "./provider/user";
import OrderProvider from "./provider/order";
import LeaderboardProvider from "./provider/leaderboard";
import LeaderBoard from "./pages/leaderboard";
import DiscordLoading from "./pages/discordLoading";
import TwitterLoading from "./pages/twitterLoading";
import NavBar from "./components/NavBar/NavBar";
import WalletProvider from "./context/Wallet";
import Error from "./pages/ErrorPage/Error";
import { Web3ReactProvider } from '@web3-react/core'
import Web3 from 'web3'

function getLibrary(provider) {
  return new Web3(provider)
}

function App() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
    <WalletProvider>
      <UserProvider>
        <OrderProvider>
          <LeaderboardProvider>
            <ToastContainer />
            <NavBar />
            <Routes>
              <Route path="/*" element={<Error />} />
              <Route path="/" element={<LeaderBoard />} />
              <Route path="/discord-auth" element={<DiscordLoading />} />
              <Route path="/twitter-auth" element={<TwitterLoading />} />
            </Routes>
          </LeaderboardProvider>
        </OrderProvider>
      </UserProvider>
    </WalletProvider>
    </Web3ReactProvider>
  );
}

export default App;
